import FooterModal from "./FooterModal";
import React from "react";

class FooterEntry extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  handleClick = () => {
    this.child.current.handleOpenModal();
  };
  render() {
    return (
      <>
        <li>
          <p
            onClick={this.handleClick}
            className="mr-4 cursor-pointer text-sm text-gray-500 hover:underline md:mr-6"
          >
            {this.props.name}
          </p>
        </li>
        <FooterModal
          ref={this.child}
          id={this.props.id}
          name={this.props.name}
          content={this.props.content}
        ></FooterModal>
      </>
    );
  }
}
export default FooterEntry;
