import fn_1 from "../images/femnurture/Login.png";
import fn_2 from "../images/femnurture/Start.png";
import fn_3 from "../images/femnurture/Lebensmittel.png";
import fn_4 from "../images/femnurture/Rezepte.png";
import fn_5 from "../images/femnurture/lang.png";
import fn_6 from "../images/femnurture/BeamNG_Video_V3.mp4";

const Femnurture = [
    fn_6,
    fn_2,
    fn_1,
    fn_3,
    fn_4,
    fn_5
]

export default Femnurture