import Project from "./Project";
import needs_logo from "../images/needs/needs_lila.png";
import needs_array from "../image_arrays/needs";
import sonoai_logo from "../images/sonoai/cocoai.png";
import sonoai_array from "../image_arrays/sonoai";
import fn_logo from "../images/femnurture/logo.png";
import fn_array from "../image_arrays/femnurture";
import beamng_array from "../image_arrays/beamng";
import beamng_logo from "../images/beamng/beamng_logo.png";
import svh_logo from "../images/svh/SVH_Logo.png";
import svh_array from "../image_arrays/svh";
import bachelorthesis_logo from "../images/bachelorthesis/Logo.png";
import bachelorthesis_array from "../image_arrays/bachelorthesis";

function ProjectOverview(props) {
  return (
    <div
      id="projects"
      className="bg-slate-800/95 text-white drop-shadow-lg backdrop-blur-sm"
    >
      <div className="mx-auto max-w-2xl p-4 sm:py-6 sm:px-6 lg:max-w-full lg:px-8">
        <h2 className="mb-5 p-5 text-4xl tracking-wide text-white md:text-5xl lg:text-7xl">
          {props.title}
        </h2>
        <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-6">
          <Project
            id="1"
            src={needs_logo}
            name="needs eine App zum navigieren mit Zwischenstopps"
            teaser="needs eine App zum navigieren mit Zwischenstopps"
            description="Im Rahmen des Moduls “Open Data Hackathon” an der Universität zu Lübeck wurde im Wintersemester 2021/22 die Web-App Needs erstellt. Dabei wurde ein funktionsfähiger Prototyp der Navigations-App entwickelt. Bei Needs handelt es sich um eine WebApp, mit der Routen entsprechend zusätzlichen Bedürfnissen geplant werden können."
            imageArray={needs_array}
          ></Project>
          <Project
            id="2"
            src={sonoai_logo}
            name="SonoAI"
            teaser="Konzeption eines Mockups für die Erkennung von TVT"
            description="Konzeption eines Mockups für die Erkennung von TVT"
            imageArray={sonoai_array}
          ></Project>
          <Project
            id="3"
            src={fn_logo}
            name="Femnurture"
            teaser="Ernährungsapp für Schwangere oder Personen die es werden wollen"
            description="In dem Modul Motivation und Emotion in der Mensch-Computer-Interaktion wurde über das gesamte erste Mastersemester in einer Gruppenarbeit eine Ernährungsapp für Schwangere oder Personen die es werden wollen konzipiert. Dabei sollte auf Emotions- und Motivationspsychologische Gestaltungsprinzipien eingegangen werden. Alle Teilaufgaben dieses Projekts wurden mit der vollen Punktzahl bewertet.
Meine Aufgaben waren die Gestaltung des Logos, des Designschemas, sowie ein Teil der Mockups."
            imageArray={fn_array}
          ></Project>
          <Project
            id="4"
            src={beamng_logo}
            name="BeamNG Evaluationsplattform"
            teaser="Entwicklung einer Weboberfläche für Experimente in dem Programm BeamNG"
            description="In dieser Arbeit wird die Entwicklung einer Plattform für die Evaluation von Studien mit der Software BeamNG beschrieben. BeamNG ist eine Simulationssoftware für Autos, bei der individuelle Szenarien erstellt werden können. Die Evaluationsplattform dient dazu, den Prozess der Studienerstellung und Durchführung zu vereinfachen und standardisieren. In der Analyse entstanden die Anforderungen an die Software. Aus der Analyse und einer Konzeption ist eine Webapplikation in Vue.js entstanden, mit der die Studien erstellt und durchgeführt werden können. Über eine Python Schnittstelle von BeamNG können Befehle gesendet werden und so die Studie geleitet werden. Der Aufbau der Webapplikation erlaubt es noch weitere Funktionen hinzuzufügen, um so die Software zu erweitern.
Wenn Sie die komplette Ausarbeitung erhalten möchten, schreiben Sie mich gerne an."
            imageArray={beamng_array}
          ></Project>
          <Project
            id="5"
            src={svh_logo}
            teaser="Erstellen der Vereinswebseite für den SVH in Typo3"
            name="Webseite für den Segelverein"
            description="Webseite für den Segelverein"
            imageArray={svh_array}
          ></Project>
          <Project
            id="6"
            src={bachelorthesis_logo}
            teaser="Erstellen einer Zeitleiste für das Historytelling Projekt"
            name="Grafische Zeitleiste für das Historytelling Projekt"
            description="In dieser Arbeit wurde das Vorgehen für die Entwicklung und Implementierung einer Zeitleiste
            für das Historytelling System beschrieben. Das Historytelling System ist eine soziale Plattform
            für Personen gehobenen Alters, auf der Geschichten geteilt und soziale Kontakte geknüpft
            werden können. Die Entwicklung der Zeitleiste verfolgte dabei das Human Centered Design
            (HCD), um die Entstehung eines gebrauchstauglichen Systems zu gewährleisten. Dabei wurde
            als Erstes der Stand der Technik, sowohl vom Historytelling System als auch von Zeitleisten im
            Internet erarbeitet. Danach wurde eine Benutzeranalyse als auch eine Aufgabenanalyse
            durchgeführt. Basierend auf diesen Analysen wurden mehrere Low Fidelity Prototypen
            entworfen. Auf Basis von den Low Fidelity Prototypen wurde ein finaler High Fidelity Prototyp
            entworfen, der dann in dem finalen System implementiert wurde. Die Implementierung erfolgte
            in Vue.js, einem JavaScript Framework, auf welchem das Historytelling System aufbaut."
            imageArray={bachelorthesis_array}
          ></Project>
          {/* <Project id="6" src="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-06.jpg" name="Simple Description" description="Erläuterung zum Projekt" imageArray={needs_array}></Project>
                <Project id="7" src="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-07.jpg" name="Simple Description" description="Erläuterung zum Projekt" imageArray={needs_array}></Project>
                <Project id="8" src="https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-08.jpg" name="Simple Description" description="Erläuterung zum Projekt" imageArray={needs_array}></Project> */}
        </div>
      </div>
    </div>
  );
}
export default ProjectOverview;
