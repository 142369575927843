import React from "react";
import "tw-elements";
import bridge from "../images/photos/IMG_3637.jpg";
import me from "../images/photos/DSC07574.jpg";
import hedda from "../images/photos/IMG_2999.JPG";
import cow from "../images/photos/IMG_4991.JPG";
import church from "../images/photos/IMG_4755.jpg";
import tower from "../images/photos/IMG_4772.jpg";

export default function photoSection({ title, subtitle, cssNames, id }) {
  return (
    <div id={id} className={cssNames}>
      <div className="mx-auto max-w-2xl p-4 sm:py-6 sm:px-6 lg:max-w-full lg:px-8">
        <div className="">
          <div className="section-content">
            <h1 className="p-5 text-4xl tracking-wide text-slate-800 md:text-5xl lg:text-7xl">
              {title}
            </h1>
            <div className="overflow-hidden text-gray-700">
              <div className="mx-auto w-full">
                <div className="-m-1 flex flex-wrap md:-m-2">
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={me}
                      ></img>
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={hedda}
                      ></img>
                    </div>
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={bridge}
                      ></img>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={cow}
                      ></img>
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={church}
                      ></img>
                    </div>
                    <div className="w-1/2 p-1 md:p-2">
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={tower}
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
