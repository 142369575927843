import needs_1 from "../images/needs/05_00_15.jpg";
import needs_2 from "../images/needs/05_00_56.jpg";
import needs_3 from "../images/needs/05_02_09.jpg";

const Needs = [
    needs_1,
    needs_2,
    needs_3
]

export default Needs