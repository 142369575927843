import React from "react";
import SingleProjectView from "./SingleProjectView";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
  }

  handleClick = () => {
    this.child.current.handleOpenModal();
  };
  render() {
    return (
      <div>
        <a onClick={this.handleClick} className="group">
          <div className="aspect-w-1 aspect-h-1 xl:aspect-w-7 xl:aspect-h-8 h-64 w-full overflow-hidden rounded-lg">
            <img
              src={this.props.src}
              alt={this.props.alt}
              className="h-full w-full cursor-pointer object-contain object-center grayscale duration-75 group-hover:grayscale-0"
            ></img>
          </div>
          <h3 className="mt-4 text-lg text-gray-100">{this.props.teaser}</h3>
        </a>
        <SingleProjectView
          ref={this.child}
          id={this.props.id}
          name={this.props.name}
          imageArray={this.props.imageArray}
          description={this.props.description}
        ></SingleProjectView>
      </div>
    );
  }
}
export default Project;
