import sonoai_0 from "../images/sonoai/SonoAI.mp4";
import sonoai_1 from "../images/sonoai/Ergebnisse.png";
import sonoai_2 from "../images/sonoai/Bericht_linkes_Bein.png";
import sonoai_3 from "../images/sonoai/Ergebnisse.png";


const Sonoai = [
    sonoai_0,
    sonoai_1,
    sonoai_2,
    sonoai_3
]

export default Sonoai