function Cv(props) {
  return (
    <div>
      <div
        id="cv"
        className="mx-auto max-w-2xl p-4 sm:py-6 sm:px-4 lg:max-w-full lg:px-8"
      >
        <h1 className="p-5 text-4xl tracking-wide text-slate-800 md:text-5xl lg:text-7xl">
          {props.title}
        </h1>
        <div className="flex flex-col items-center justify-center">
          <h2 className="p-5 text-2xl tracking-wide text-slate-800 md:text-3xl lg:text-4xl">
            Moin!
          </h2>
          <p className="m-auto w-full md:w-1/2">
            Ich bin Morten, 25 Jahre alt, und studiere derzeit im vierten
            Fachsemester Medieninformatik an der Universität zu Lübeck. Vor
            meinem Masterstudium habe ich auch bereits meinen Bachelor in
            Medieninformatik in Lübeck erfolgreich abgeschlossen. Meinen Master
            werde ich voraussichtlich Ende 2022 abschließen. Mein persönlicher
            Fokus im Studium liegt im Bereich Webdevelopment, in dem ich bereits
            einige Erfahrungen sammeln konnte, sei es innerhalb des Studiums
            oder auch beruflich oder privat. In meiner Freizeit fotografiere ich
            gerne und im Sommer bin ich hauptsächlich auf der Ostsee zu finden,
            sei es mit einem Segelschiff oder auf einem Surfboard.
          </p>
        </div>
      </div>
      <div
        id="cv"
        className="mx-auto max-w-2xl p-4 sm:py-6 sm:px-4 lg:max-w-full lg:px-8"
      >
        <div className="flex flex-col items-center justify-center">
          <h2 className="p-5 text-2xl tracking-wide text-slate-800 md:text-3xl lg:text-4xl">
            Lebenslauf
          </h2>
          <div className="grid grid-cols-1 gap-y-5 gap-x-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            <div className="col-span-1 w-full overflow-x-auto">
              <div className="flex max-w-xs flex-nowrap overflow-x-auto rounded-lg py-2 sm:px-4 md:max-w-2xl lg:max-w-4xl lg:px-8">
                <div className="m-auto flex  flex-none">
                  <table className="table-auto">
                    <thead className="border-b">
                      <tr className="bg-slate-800/95 p-1 text-white backdrop-blur-sm">
                        <th scope="col">
                          <a>Dauer</a>
                          <br></br>
                          <a></a>
                        </th>
                        <th scope="col">
                          <a>Ausbildung</a>
                          <br></br>
                          <a>Abschluss</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          2020 – voraussichtlich 2022
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Master Medieninformatik, Uni Lübeck
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          2017 – 2020
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Bachelor Medieninformatik, Uni Lübeck
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          2014 – Abitur 2017
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Immanuel-Kant-Schule, Reinfeld
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          2013 – 2014
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          High School Jahr in den USA
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          2007 – 2013
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Immanuel-Kant-Schule, Reinfeld
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-span-1 w-full overflow-x-auto">
              <div className="flex w-full max-w-xs flex-nowrap overflow-x-auto py-2 sm:px-4 md:max-w-2xl lg:max-w-4xl lg:px-8">
                <div className="m-auto flex  flex-none">
                  <table className="table-auto">
                    <thead className="border-b">
                      <tr className="bg-slate-800/95 p-1 text-white backdrop-blur-sm">
                        <th scope="col">
                          <a>Programmierkentnisse</a>
                          <br className="h-0"></br>
                          <a>Frameworks</a>
                        </th>
                        <th scope="col">Niveau</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          HTML + CSS + JavaScript + TypeScript
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Erweiterte Kenntnisse
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          React
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Erweiterte Kenntnisse
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Vue.js
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Grundkenntnisse
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Python
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Grundkenntnisse
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Typo3
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Erweiterte Kenntnisse
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Java
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Grundkenntnisse
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-span-1 overflow-x-auto">
              <div className="flex max-w-xs flex-nowrap overflow-x-auto py-2 sm:px-4 md:max-w-2xl lg:max-w-4xl lg:px-8">
                <div className="m-auto flex  flex-none">
                  <table className="table-auto">
                    <thead className="border-b">
                      <tr className="bg-slate-800/95 p-1 text-white backdrop-blur-sm">
                        <th scope="col">
                          <a>Grafiken</a>
                          <br className="h-0"></br>
                          <a>Illustrationen</a>
                        </th>
                        <th scope="col">Niveau</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Adobe XD
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Erweiterte Kenntnisse
                        </td>
                      </tr>
                      <tr className="border-b bg-white">
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Adobe Lightroom
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-sm font-light text-slate-800">
                          Erweiterte Kenntnisse
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cv;
