import React from "react";
import Profilepicture from "../images/profile.jpg";
import BgImage from "../images/StartBackground.jpg";

export default function Header() {
  return (
    <section className="bg body-font text-white">
      <div
        style={{ backgroundImage: `url(${BgImage})` }}
        className="bg-cover bg-center bg-no-repeat"
      >
        <div className="bg-slate-800/50 backdrop-blur-sm">
          <div className="container relative mx-auto flex flex-col items-center justify-center px-5 py-24">
            <img
              className="mb-10 w-5/6 rounded-full object-cover object-center blur-none md:w-3/6 lg:w-2/6"
              alt="me"
              src={Profilepicture}
            ></img>
            <h1 className="mb-8 text-4xl blur-none md:text-6xl lg:text-8xl">
              Morten Lütje
            </h1>
            <h2 className="text-2xl blur-none md:text-3xl lg:text-4xl">
              B.Sc. Medieninformatik
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
