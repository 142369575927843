import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link, animateScroll as scroll } from "react-scroll";
import Header from "./Header";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const section1 = "Lebenslauf";
  const section2 = "Projekte";
  const section3 = "Bilder";
  const section4 = "Kontakt";
  return (
    <div>
      <nav className="sticky top-0 z-10 bg-slate-800/95 drop-shadow-lg backdrop-blur-sm">
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <h4 className="px-3 py-2 text-3xl font-bold text-white">
                  Morten Lütje
                </h4>
              </div>
              <div className="hidden md:block">
                <div className="pointer-events-auto ml-32 flex items-baseline space-x-4">
                  <Link
                    activeClass="active"
                    to="cv"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="pointer-events-auto rounded-md px-5 py-2 text-base font-bold text-white hover:bg-gray-700"
                  >
                    {section1}
                  </Link>
                  <Link
                    activeClass="active"
                    to="projects"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="pointer-events-auto rounded-md px-5 py-2 text-base font-bold text-white hover:bg-gray-700"
                  >
                    {section2}
                  </Link>
                  <Link
                    activeClass="active"
                    to="images"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="pointer-events-auto rounded-md px-5 py-2 text-base font-bold text-white hover:bg-gray-700"
                  >
                    {section3}
                  </Link>
                  <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="pointer-events-auto rounded-md px-5 py-2 text-base font-bold text-white hover:bg-gray-700"
                  >
                    {section4}
                  </Link>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-400 hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                <Link
                  onClick={() => setIsOpen(!isOpen)}
                  activeClass="active"
                  to="cv"
                  spy={true}
                  smooth={true}
                  offset={-192}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-700"
                >
                  {section1}
                </Link>
                <Link
                  onClick={() => setIsOpen(!isOpen)}
                  activeClass="active"
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-192}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-700"
                >
                  {section2}
                </Link>
                <Link
                  onClick={() => setIsOpen(!isOpen)}
                  activeClass="active"
                  to="images"
                  spy={true}
                  smooth={true}
                  offset={-192}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-700"
                >
                  {section3}
                </Link>
                <Link
                  onClick={() => setIsOpen(!isOpen)}
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-192}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-gray-700"
                >
                  {section4}
                </Link>
              </div>
            </div>
          )}
        </Transition>
      </nav>
      <Header></Header>
    </div>
  );
}

export default Nav;
