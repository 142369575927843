import React from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");
class Impressum extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.state = {
      showModal: false,
    };
  }
  handleOpenModal() {
    document.body.style.overflow = "hidden";
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    document.body.style.overflow = "unset";
    this.setState({ showModal: false });
  }
  render() {
    return (
      <div key={this.props.name}>
        <ReactModal
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          shouldFocusAfterRender={false}
          id={this.props.name}
          /* preventScroll={true} */
          className="position: absolute top-1 left-1 right-1 bottom-1 overflow-auto rounded-xl bg-slate-800 text-white lg:top-10 lg:left-10 lg:right-10 lg:bottom-10"
          overlayClassName="position: fixed top-0 left-0 right-0 bottom-0 bg-slate-800"
        >
          <div className="body-font z-50 bg-white text-gray-800">
            <div className="position: fixed">
              <button
                onClick={() => this.handleCloseModal(!this.showModal)}
                type="button"
                className="inline-flex items-center justify-center rounded-lg bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-800"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="container mx-auto flex flex-col items-center justify-center px-5 py-24">
              <div className="w-full text-center lg:w-2/3">
                <h1 className="title-font mb-4 text-3xl font-medium text-gray-900 sm:text-4xl">
                  {this.props.name}
                </h1>
                {this.props.content}
                <div className="mt-4 flex justify-center">
                  <button
                    onClick={() => this.handleCloseModal(!this.showModal)}
                    className="inline-block rounded bg-amber-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-amber-700 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg"
                  >
                    Schließen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default Impressum;
