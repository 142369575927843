import Profilepicture from "../images/profile_2.jpg";

function Contact(props) {
  return (
    <div id={props.id} className={props.cssNames}>
      <div className="mx-auto max-w-2xl p-4 sm:py-6 sm:px-6 lg:max-w-full lg:px-8">
        <h1 className="p-5 text-4xl tracking-wide md:text-5xl lg:text-7xl">
          {props.title}
        </h1>
        <div className="flex flex-col items-center justify-center">
          <div className="mt-5 mb-5 flex flex-col items-center rounded-lg bg-white drop-shadow-lg md:max-w-xl md:flex-row">
            <img
              className="h-96 w-full rounded-t-lg object-cover object-top md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
              src={Profilepicture}
              alt=""
            ></img>
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                Kontaktdaten:
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">Morten Lütje</p>
              <p className="mb-3 font-normal text-gray-700 ">
                morten.luetje@gmx.de
              </p>
              <p className="mb-3 font-normal text-gray-700 ">
                Für Anfragen einfach per E-Mail bei mir melden.
              </p>
              <button
                onClick={() =>
                  (window.location = "mailto:morten.luetje@gmx.de")
                }
                className="inline-block w-1/2 rounded bg-amber-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-amber-700 hover:shadow-lg focus:bg-amber-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-amber-800 active:shadow-lg"
              >
                Anfrage schicken
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
